import React from 'react'
import './services.css'
import { FaCheck } from "react-icons/fa";



const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className='service'>
          <div className='service__head'>
            <h3>Web Services</h3>
          </div>

          <ul className='service__list'>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Maintenance and Upkeep</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Support on Weekends</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Domain Renewal</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Site Hosting</p>
            </li>
          </ul>
        </article>
        {/* end of web services */}
        <article className='service'>
          <div className='service__head'>
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Simple Site: 1-3 pages</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Advanced Site: More than 3 pages</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Mobile App/Game</p>
            </li>
          </ul>
        </article>
        {/* end of web development */}
        <article className='service'>
          <div className='service__head'>
            <h3>Content Creation</h3>
          </div>

          <ul className='service__list'>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Social Media Page Upkeep</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Google Ads Page</p>
            </li>
            {/* <li>
              <FaCheck className='service__list-icon' />
              <p></p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p></p>
            </li> */}
          </ul>
        </article>
        {/* end of content creation */}
      </div>
    </section>
  )
}

export default Services
